import CryptoJS from 'crypto-js'

let i = 0
let map = []
let seed = Math.random() * Math.pow(10, 20)

// 将种子和索引组合并生成随机数的函数
function seededRandom(seed, index) {
  // 将种子和索引组合成一个字符串
  const seedString = `${seed}-${index}`;
  // 使用SHA-256哈希函数生成哈希值
  const hash = CryptoJS.SHA256(seedString);
  // 将哈希值的前8个字符转换为一个整数
  const hashHex = hash.toString(CryptoJS.enc.Hex);
  const hashInt = parseInt(hashHex.substring(0, 8), 16);
  // 将整数转换为一个介于0和1之间的浮点数
  const randomNum = hashInt / 0xffffffff;
  return randomNum;
}

for (var a = 0; a < 20000; a++) {
  map[a] = seededRandom(seed, a)
}

export const customRandom = function (pre) {
  const r = map[pre || i]
  if (!pre) {
    i++
  }
  return r
}

export function randomFromInterval (min, max) {
  const r = customRandom()
  return r * (max - min) + min
}

export const resetIndex = () => {
  i = 0
}

export const resetSeed = (s) => {
  seed = s || Math.random() * Math.pow(10, 20)
  console.log(seed)
  for (var a = 0; a < 20000; a++) {
    map[a] = seededRandom(seed, a)
  }
}

export const getSeed = () => {
  return seed
}