// src/web3-onboard.js

import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'

const injected = injectedModule()

const onboard = Onboard({
  connect: {
    autoConnectLastWallet: true
  },
  wallets: [params => {
    const isMobile = window.innerWidth <= 500
    return injected(params).filter(item => isMobile ? true : item.label.toLowerCase() === "metamask")
  }],
  chains: [
    {
      id: '0x2105', // Base链的chain ID，具体ID请以Base官方文档为准
      token: 'ETH', // Base链上使用的代币符号
      label: 'Base LlamaNodes', // Base链的标签名称
      rpcUrl: 'https://base.llamarpc.com', // 请替换为实际的Base链RPC URL
      blockExplorerUrl: 'https://basescan.org'
    }
  ],
  appMetadata: {
    name: 'Ugly Face',
    icon: '/avatar.svg',
    description: 'Ugly Face'
  }
})

export default onboard
