<template>
  <div class="wrap">
    <div class="container">
      <div style="position: relative;">
        <img class="cover" :src="img" alt="">
        <svg
          class="face"
          viewBox="-100 -100 200 200"
          xmlns="http://www.w3.org/2000/svg"
          width="500"
          height="500"
          id="face-svg"
        >
          <defs>
            <clipPath id="leftEyeClipPath">
              <polyline :points="eyeLeftCountour" />
            </clipPath>
            <clipPath id="rightEyeClipPath">
              <polyline :points="eyeRightCountour" />
            </clipPath>

            <filter id="fuzzy">
              <feTurbulence
                id="turbulence"
                baseFrequency="0.05"
                numOctaves="3"
                type="noise"
                result="noise"
              />
              <feDisplacementMap in="SourceGraphic" in2="noise" scale="2" />
            </filter>
            <linearGradient id="rainbowGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop
                offset="0%"
                :style="
                  'stop-color: ' +
                  hairColors[Math.floor(customRandom(10001) * 10)] +
                  ';  stop-opacity: 1'
                "
              />
              <stop
                :offset="dyeColorOffset"
                :style="
                  'stop-color: ' +
                  hairColors[Math.floor(customRandom(10001) * hairColors.length)] +
                  ';  stop-opacity: 1'
                "
              />
              <stop
                offset="100%"
                :style="
                  'stop-color: ' +
                  hairColors[Math.floor(customRandom(10001) * hairColors.length)] +
                  ';  stop-opacity: 1'
                "
              />
            </linearGradient>
          </defs>
          <rect
            x="-100"
            y="-100"
            width="100%"
            height="100%"
            :fill="
              backgroundColors[Math.floor(customRandom(10000) * backgroundColors.length)]
            "
          />
          <polyline
            id="faceContour"
            :points="computedFacePoints"
            fill="#ffc9a9"
            stroke="black"
            :stroke-width="3.0 / faceScale"
            stroke-linejoin="round"
            filter="url(#fuzzy)"
          />

          <g
            :transform="
              'translate(' +
              (center[0] + distanceBetweenEyes + rightEyeOffsetX) +
              ' ' +
              -(-center[1] + eyeHeightOffset + rightEyeOffsetY) +
              ')'
            "
          >
            <polyline
              id="rightCountour"
              :points="eyeRightCountour"
              fill="white"
              stroke="white"
              :stroke-width="0.0 / faceScale"
              stroke-linejoin="round"
              filter="url(#fuzzy)"
            />
          </g>
          <g
            :transform="
              'translate(' +
              -(center[0] + distanceBetweenEyes + leftEyeOffsetX) +
              ' ' +
              -(-center[1] + eyeHeightOffset + leftEyeOffsetY) +
              ')'
            "
          >
            <polyline
              id="leftCountour"
              :points="eyeLeftCountour"
              fill="white"
              stroke="white"
              :stroke-width="0.0 / faceScale"
              stroke-linejoin="round"
              filter="url(#fuzzy)"
            />
          </g>
          <g
            :transform="
              'translate(' +
              (center[0] + distanceBetweenEyes + rightEyeOffsetX) +
              ' ' +
              -(-center[1] + eyeHeightOffset + rightEyeOffsetY) +
              ')'
            "
          >
            <polyline
              id="rightUpper"
              :points="eyeRightUpper"
              fill="none"
              stroke="black"
              :stroke-width="3.0 / faceScale"
              stroke-linejoin="round"
              filter="url(#fuzzy)"
            />
            <polyline
              id="rightLower"
              :points="eyeRightLower"
              fill="none"
              stroke="black"
              :stroke-width="4.0 / faceScale"
              stroke-linejoin="round"
              filter="url(#fuzzy)"
            />
            <circle
              v-for="i in 10"
              :key="i"
              :r="customRandom() * 2 + 3.0"
              :cx="rightPupilShiftX + customRandom() * 5 - 2.5"
              :cy="rightPupilShiftY + customRandom() * 5 - 2.5"
              stroke="black"
              fill="none"
              :stroke-width="1.0"
              filter="url(#fuzzy)"
              clip-path="url(#rightEyeClipPath)"
            />
          </g>
          <g
            :transform="
              'translate(' +
              -(center[0] + distanceBetweenEyes + leftEyeOffsetX) +
              ' ' +
              -(-center[1] + eyeHeightOffset + leftEyeOffsetY) +
              ')'
            "
          >
            <polyline
              id="leftUpper"
              :points="eyeLeftUpper"
              fill="none"
              stroke="black"
              :stroke-width="4.0 / faceScale"
              stroke-linejoin="round"
              filter="url(#fuzzy)"
            />
            <polyline
              id="leftLower"
              :points="eyeLeftLower"
              fill="none"
              stroke="black"
              :stroke-width="4.0 / faceScale"
              stroke-linejoin="round"
              filter="url(#fuzzy)"
            />
            <circle
              v-for="i in 10"
              :key="i"
              :r="customRandom() * 2 + 3.0"
              :cx="leftPupilShiftX + customRandom() * 5 - 2.5"
              :cy="leftPupilShiftY + customRandom() * 5 - 2.5"
              stroke="black"
              fill="none"
              :stroke-width="1.0"
              filter="url(#fuzzy)"
              clip-path="url(#leftEyeClipPath)"
            />
          </g>
          <g id="hairs">
            <polyline
              v-for="(hair, index) in hairs"
              :key="index"
              :points="hair"
              fill="none"
              :stroke="hairColor"
              :stroke-width="2"
              stroke-linejoin="round"
              filter="url(#fuzzy)"
            />
          </g>
          <g id="pointNose" v-if="customRandom(10005) > 0.5">
            <g id="rightNose">
              <circle
                v-for="i in 10"
                :key="i"
                :r="customRandom() * 2 + 1.0"
                :cx="rightNoseCenterX + customRandom() * 4 - 2"
                :cy="rightNoseCenterY + customRandom() * 4 - 2"
                stroke="black"
                fill="none"
                :stroke-width="1.0"
                filter="url(#fuzzy)"
              />
            </g>
            <g id="leftNose">
              <circle
                v-for="i in 10"
                :key="i"
                :r="customRandom() * 2 + 1.0"
                :cx="leftNoseCenterX + customRandom() * 4 - 2"
                :cy="leftNoseCenterY + customRandom() * 4 - 2"
                stroke="black"
                fill="none"
                :stroke-width="1.0"
                filter="url(#fuzzy)"
              />
            </g>
          </g>
          <g id="lineNose" v-else>
            <path
              :d="
                'M ' +
                leftNoseCenterX +
                ' ' +
                leftNoseCenterY +
                ', Q' +
                rightNoseCenterX +
                ' ' +
                rightNoseCenterY * 1.5 +
                ',' +
                (leftNoseCenterX + rightNoseCenterX) / 2 +
                ' ' +
                -eyeHeightOffset * 0.2
              "
              fill="none"
              stroke="black"
              :stroke-width="3"
              stroke-linejoin="round"
              filter="url(#fuzzy)"
            ></path>
          </g>
          <g id="mouth">
            <polyline
              :points="mouthPoints"
              fill="rgb(215,127,140)"
              stroke="black"
              :stroke-width="3"
              stroke-linejoin="round"
              filter="url(#fuzzy)"
            />
          </g>
        </svg>
      </div>
      <div class="progress-container">
        <div class="progress-wrapper">
            <div class="progress-text">{{ minted.toLocaleString() }}/10,000</div> <!-- You can set this dynamically with JavaScript -->
            <div :style="{ 'width': minted / 100 + '%' }" class="progress-bar"></div>
        </div>
      </div>
      <button @click="generateFace()">ANOTHER</button>
      <template v-if="!wallet">
        <button  @click="connectWallet()">CONNECT WALLET</button>
      </template>
      <template v-else>
        <button style="cursor: pointer;" @click="openLink">{{ formatWallet(walletAddress) }}</button>
        <button :class="{'disabled': minted >= 10000 || !inited}" @click="mint">{{ isLoading ? 'Loading' : 'MINT' }}</button>
      </template>

      <div class="tips">
        <h3 style="margin-bottom: 12px;">
          Disclaimer Agreement
        </h3>

        <div style="font-size: 14px;">
          Please note that this project is for entertainment purposes only and has no practical utility. It is intended solely for users to mint and appreciate its creativity and holds no intrinsic value. If you have any expectations of profit from this product, please do not proceed with minting. We are not responsible for any financial loss or other consequences arising from the use of this product. Thank you for your understanding and support.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as faceShape from "../utils/face_shape.js";
import * as eyeShape from "../utils/eye_shape.js";
import * as hairLines from "../utils/hair_lines.js";
import * as mouthShape from "../utils/mouth_shape.js";
import { randomFromInterval, resetIndex, resetSeed, customRandom, getSeed } from '../utils/index'
import onboard from '../utils/web3'
import Web3 from 'web3'

import abi from '../assets/uglyface.json'
const contractAddress = '0xa3db5d3fe3e8bb1ba500f76d70b6db5d462db9f2'

export default {
  name: "FaceGenerator",
  data() {
    return {
      inited: false,
      minted: 0,
      img: '',
      isLoading: false,
      walletAddress: '',
      wallet: null,
      faceScale: 1.8, // face scale
      computedFacePoints: [], // the polygon points for face countour
      eyeRightUpper: [], // the points for right eye upper lid
      eyeRightLower: [],
      eyeRightCountour: [], // for the white part of the eye
      eyeLeftUpper: [],
      eyeLeftLower: [],
      eyeLeftCountour: [],
      faceHeight: 0, // the height of the face
      faceWidth: 0, // the width of the face
      center: [0, 0], // the center of the face
      distanceBetweenEyes: 0, // the distance between the eyes
      leftEyeOffsetX: 0, // the offset of the left eye
      leftEyeOffsetY: 0, // the offset of the left eye
      rightEyeOffsetX: 0, // the offset of the right eye
      rightEyeOffsetY: 0, // the offset of the right eye
      eyeHeightOffset: 0, // the offset of the eye height
      leftEyeCenter: [0, 0], // the center of the left eye
      rightEyeCenter: [0, 0], // the center of the right eye
      rightPupilShiftX: 0, // the shift of the right pupil
      rightPupilShiftY: 0, // the shift of the right pupil
      leftPupilShiftX: 0, // the shift of the left pupil
      leftPupilShiftY: 0, // the shift of the left pupil
      rightNoseCenterX: 0, // the center of the right nose
      rightNoseCenterY: 0, // the center of the right nose
      leftNoseCenterX: 0, // the center of the left nose
      leftNoseCenterY: 0, // the center of the left nose
      hairs: [],
      hairColors: [
        "rgb(0, 0, 0)", // Black
        "rgb(44, 34, 43)", // Dark Brown
        "rgb(80, 68, 68)", // Medium Brown
        "rgb(167, 133, 106)", // Light Brown
        "rgb(220, 208, 186)", // Blond
        "rgb(233, 236, 239)", // Platinum Blond
        "rgb(165, 42, 42)", // Red
        "rgb(145, 85, 61)", // Auburn
        "rgb(128, 128, 128)", // Grey
        "rgb(185, 55, 55)", // fire
        "rgb(255, 192, 203)", // Pastel Pink
        "rgb(255, 105, 180)", // Bright Pink
        "rgb(230, 230, 250)", // Lavender
        "rgb(64, 224, 208)", // Turquoise
        "rgb(0, 191, 255)", // Bright Blue
        "rgb(148, 0, 211)", // Deep Purple
        "rgb(50, 205, 50)", // Lime Green
        "rgb(255, 165, 0)", // Vivid Orange
        "rgb(220, 20, 60)", // Crimson Red
        "rgb(192, 192, 192)", // Silver
      ],
      hairColor: "black",
      dyeColorOffset: "50%",
      backgroundColors: [
        "rgb(245, 245, 220)", // Soft Beige
        "rgb(176, 224, 230)", // Pale Blue
        "rgb(211, 211, 211)", // Light Grey
        "rgb(152, 251, 152)", // Pastel Green
        "rgb(255, 253, 208)", // Cream
        "rgb(230, 230, 250)", // Muted Lavender
        "rgb(188, 143, 143)", // Dusty Rose
        "rgb(135, 206, 235)", // Sky Blue
        "rgb(245, 255, 250)", // Mint Cream
        "rgb(245, 222, 179)", // Wheat
        "rgb(47, 79, 79)", // Dark Slate Gray
        "rgb(72, 61, 139)", // Dark Slate Blue
        "rgb(60, 20, 20)", // Dark Brown
        "rgb(25, 25, 112)", // Midnight Blue
        "rgb(139, 0, 0)", // Dark Red
        "rgb(85, 107, 47)", // Olive Drab
        "rgb(128, 0, 128)", // Purple
        "rgb(0, 100, 0)", // Dark Green
        "rgb(0, 0, 139)", // Dark Blue
        "rgb(105, 105, 105)", // Dim Gray
      ],
      mouthPoints: [],
    };
  },
  methods: {
    customRandom,
    randomFromInterval,
    async checkChain (wallet) {
      // console.log(33333, onboard)
      const correctChainId = '0x2105'
      try {
        const network = this.wallet.chains[0].id
        if (network !== correctChainId) {
          onboard.state.actions.customNotification({
            message: 'Network error, please connect to the Base LlamaNodes.',
            type: 'error',
            // autoDismiss: 5000,
            async onClick () {
              try {
                // 检查 MetaMask 是否已安装
                if (typeof window.ethereum !== 'undefined') {
                  // 自定义网络参数
                  const chainId = '0x2105' // 替换为实际的Coinbase链ID
                  const rpcUrl = 'https://base.llamarpc.com' // 替换为实际的Base链RPC URL

                  await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                      chainId: chainId,
                      chainName: 'Base LlamaNodes',
                      nativeCurrency: {
                        name: 'Ethereum',
                        symbol: 'ETH',
                        decimals: 18
                      },
                      rpcUrls: [rpcUrl],
                      blockExplorerUrls: ['https://basescan.org'] // 替换为实际的区块浏览器URL
                    }]
                  })

                  console.log('Custom network added successfully')
                } else {
                  throw new Error('MetaMask is not installed')
                }
              } catch (e) {
                console.error(e)
              }
            }
          })
          return false
        }
        return true
      } catch (e) {
        console.error(e)
        onboard.state.actions.customNotification({
          message: 'Network error, please connect to the Base LlamaNodes.',
          type: 'error',
          // autoDismiss: 5000,
          async onClick () {
            try {
              // 检查 MetaMask 是否已安装
              if (typeof window.ethereum !== 'undefined') {
                // 自定义网络参数
                const chainId = '0x2105' // 替换为实际的Coinbase链ID
                const rpcUrl = 'https://base.llamarpc.com' // 替换为实际的Base链RPC URL

                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [{
                    chainId: chainId,
                    chainName: 'Base LlamaNodes',
                    nativeCurrency: {
                      name: 'Ethereum',
                      symbol: 'ETH',
                      decimals: 18
                    },
                    rpcUrls: [rpcUrl],
                    blockExplorerUrls: ['https://basescan.org'] // 替换为实际的区块浏览器URL
                  }]
                })

                console.log('Custom network added successfully')
              } else {
                throw new Error('MetaMask is not installed')
              }
            } catch (e) {
              console.error(e)
            }
          }
        })
        return false
      }
    },
    formatWallet (value) {
      if (!value) {
        return '--'
      }

      return value.slice(0, 4) + '...' + value.slice(-4)
    },
    async connectWallet (params) {
      const wallets = await onboard.connectWallet(params)
      if (wallets[0]) {
        this.wallet = wallets[0]
        this.walletAddress = wallets[0].accounts[0].address
        this.checkChain(this.wallet)
        localStorage.setItem('isConnect', 1)
        // this.$nextTick(() => {
        //   this.generateFace(getSeed())
        // })

        this.wallet.provider.on('chainChanged', async (chainId) => {
          location.reload()
        })
        this.wallet.provider.on('accountsChanged', async (chainId) => {
          location.reload()
        })
        this.wallet.provider.on('disconnect', async (chainId) => {
          localStorage.removeItem('isConnect')
          location.reload()
        })
      }
    },
    generateFace (seed) {
      console.log(123)
      resetIndex()
      resetSeed(seed)
      let faceResults = faceShape.generateFaceCountourPoints();
      this.computedFacePoints = faceResults.face;
      this.faceHeight = faceResults.height;
      this.faceWidth = faceResults.width;
      this.center = faceResults.center;
      let eyes = eyeShape.generateBothEyes(this.faceWidth / 2);
      let left = eyes.left;
      let right = eyes.right;
      this.eyeRightUpper = right.upper;
      this.eyeRightLower = right.lower;
      this.eyeRightCountour = right.upper
        .slice(10, 90)
        .concat(right.lower.slice(10, 90).reverse());
      this.eyeLeftUpper = left.upper;
      this.eyeLeftLower = left.lower;
      this.eyeLeftCountour = left.upper
        .slice(10, 90)
        .concat(left.lower.slice(10, 90).reverse());
      this.distanceBetweenEyes = randomFromInterval(
        this.faceWidth / 4.5,
        this.faceWidth / 4
      );
      this.eyeHeightOffset = randomFromInterval(
        this.faceHeight / 8,
        this.faceHeight / 6
      );
      this.leftEyeOffsetX = randomFromInterval(
        -this.faceWidth / 20,
        this.faceWidth / 10
      );
      this.leftEyeOffsetY = randomFromInterval(
        -this.faceHeight / 50,
        this.faceHeight / 50
      );
      this.rightEyeOffsetX = randomFromInterval(
        -this.faceWidth / 20,
        this.faceWidth / 10
      );
      this.rightEyeOffsetY = randomFromInterval(
        -this.faceHeight / 50,
        this.faceHeight / 50
      );
      this.leftEyeCenter = left.center[0];
      this.rightEyeCenter = right.center[0];
      this.leftPupilShiftX = randomFromInterval(
        -this.faceWidth / 20,
        this.faceWidth / 20
      );

      // now we generate the pupil shifts
      // we first pick a point from the upper eye lid
      let leftInd0 = Math.floor(randomFromInterval(10, left.upper.length - 10));
      let rightInd0 = Math.floor(
        randomFromInterval(10, right.upper.length - 10)
      );
      let leftInd1 = Math.floor(randomFromInterval(10, left.upper.length - 10));
      let rightInd1 = Math.floor(
        randomFromInterval(10, right.upper.length - 10)
      );
      let leftLerp = randomFromInterval(0.2, 0.8);
      let rightLerp = randomFromInterval(0.2, 0.8);

      this.leftPupilShiftY =
        left.upper[leftInd0][1] * leftLerp +
        left.lower[leftInd1][1] * (1 - leftLerp);
      this.rightPupilShiftY =
        right.upper[rightInd0][1] * rightLerp +
        right.lower[rightInd1][1] * (1 - rightLerp);
      this.leftPupilShiftX =
        left.upper[leftInd0][0] * leftLerp +
        left.lower[leftInd1][0] * (1 - leftLerp);
      this.rightPupilShiftX =
        right.upper[rightInd0][0] * rightLerp +
        right.lower[rightInd1][0] * (1 - rightLerp);

      var numHairLines = [];
      var numHairMethods = 4;
      for (var i = 0; i < numHairMethods; i++) {
        numHairLines.push(Math.floor(randomFromInterval(0, 50)));
      }
      this.hairs = [];
      if (customRandom() > 0.3) {
        this.hairs = hairLines.generateHairLines0(
          this.computedFacePoints,
          numHairLines[0] * 1 + 10
        );
      }
      if (customRandom() > 0.3) {
        this.hairs = this.hairs.concat(
          hairLines.generateHairLines1(
            this.computedFacePoints,
            numHairLines[1] / 1.5 + 10
          )
        );
      }
      if (customRandom() > 0.5) {
        this.hairs = this.hairs.concat(
          hairLines.generateHairLines2(
            this.computedFacePoints,
            numHairLines[2] * 3 + 10
          )
        );
      }
      if (customRandom() > 0.5) {
        this.hairs = this.hairs.concat(
          hairLines.generateHairLines3(
            this.computedFacePoints,
            numHairLines[3] * 3 + 10
          )
        );
      }
      this.rightNoseCenterX = randomFromInterval(
        this.faceWidth / 18,
        this.faceWidth / 12
      );
      this.rightNoseCenterY = randomFromInterval(0, this.faceHeight / 5);
      this.leftNoseCenterX = randomFromInterval(
        -this.faceWidth / 18,
        -this.faceWidth / 12
      );
      this.leftNoseCenterY =
        this.rightNoseCenterY +
        randomFromInterval(-this.faceHeight / 30, this.faceHeight / 20);
      this.hairColor = this.hairColors[Math.floor(customRandom(10001) * 10)];
      // if (customRandom() > 0.1) {
      //   // use natural hair color
      //   this.hairColor = this.hairColors[Math.floor(customRandom(10001) * 10)];
      // } else {
      //   this.hairColor = "url(#rainbowGradient)";
      //   this.dyeColorOffset = randomFromInterval(0, 100) + "%";
      // }

      var choice = Math.floor(customRandom() * 3);
      if (choice == 0) {
        this.mouthPoints = mouthShape.generateMouthShape0(
          this.computedFacePoints,
          this.faceHeight,
          this.faceWidth
        );
      } else if (choice == 1) {
        this.mouthPoints = mouthShape.generateMouthShape1(
          this.computedFacePoints,
          this.faceHeight,
          this.faceWidth
        );
      } else {
        this.mouthPoints = mouthShape.generateMouthShape2(
          this.computedFacePoints,
          this.faceHeight,
          this.faceWidth
        );
      }

      this.$nextTick(() => {
        const svg = document.getElementById("face-svg");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = document.createElement("img");
        const svgSize = svg.getBoundingClientRect();
        canvas.width = svgSize.width;
        canvas.height = svgSize.height;

        // console.log(svgData)
        img.setAttribute("src", "data:image/svg+xml;base64," + btoa(svgData));
        this.img = "data:image/svg+xml;base64," + btoa(svgData)
        // img.onload = function () {
        //   this.img = img
        // };
      })
    },
    async mint () {
      if (this.isLoading || this.minted >= 10000 || !this.inited || !this.checkChain()) {
        return
      }
      this.isLoading = true
      try {
        const web3 = new Web3(this.wallet.provider)
        const contract = new web3.eth.Contract(abi, contractAddress)
        await contract.methods.mint(getSeed().toString()).send({
          from: this.walletAddress,
          value: web3.utils.toWei('0.001', 'ether')
        }).on('transactionHash', (hash) => {
          console.log('Transaction Hash:', hash)
        }).on('receipt', (receipt) => {
          // console.log('Transaction Receipt:', receipt)
          onboard.state.actions.customNotification({
            message: 'Transaction sent. Click to view the transaction.',
            type: 'success',
            autoDismiss: 5000,
            onClick () {
              window.open(`https://basescan.org/tx/${receipt.transactionHash}`)
            }
          })
          this.isLoading = false
          this.initData()
          this.generateFace()
        }).on('confirmation', (confirmationNumber, receipt) => {
          if (Number(confirmationNumber) === 2) {
            this.initData()
          }
          // console.log('Transaction Confirmation:', confirmationNumber, receipt)
        })
      } catch (e) {
        console.log(e)
        onboard.state.actions.customNotification({
          message: e.message || e.msg || 'Failed to call contract function.',
          type: 'error'
        })
      }

      this.isLoading = false
    },
    downloadSVGAsPNG() {
      // download our svg as png
      const svg = document.getElementById("face-svg");
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = document.createElement("img");
      const svgSize = svg.getBoundingClientRect();
      canvas.width = svgSize.width;
      canvas.height = svgSize.height;

      // console.log(svgData)
      img.setAttribute("src", "data:image/svg+xml;base64," + btoa(svgData));
      img.onload = function () {
        ctx.drawImage(img, 0, 0);
        const a = document.createElement("a");
        const e = new MouseEvent("click");
        a.download = "face.png";
        a.href = canvas.toDataURL("image/png");
        a.dispatchEvent(e);
      };
    },
    openLink () {
      window.open(`https://basescan.org/address/${this.walletAddress}`)
    },
    async initData () {
      try {
        const web3 = new Web3(window.ethereum)
        const contract = new web3.eth.Contract(abi, contractAddress)
        const data = await contract.methods.totalSupply().call()
        this.minted = Number(data)
        this.inited = true
      } catch (err) {
        console.log(err)
      }
    }
  },
  mounted() {
    this.generateFace();
    this.initData()
    if (localStorage.getItem('isConnect') == 1) {
      this.connectWallet({
        autoSelect: {
          label: 'Auto Connect Wallet',
          disableModals: true
        }
      })
    }
  },
};
</script>

<style>
.wrap {
  position: relative;
  z-index: 0;
}

.tips {
  max-width: 500px;
  box-sizing: border-box;
  padding: 24px;
  border-radius: 8px;
  margin-top: 24px;
  border: 1px solid #2F2F3047;
}

.progress-container {
  margin-bottom: 24px;
  margin-top: 24px;
  width: 80%;
  height: 30px;
  background-color: #bbb;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  height: 30px;
  min-width: 3px;
  width: 0%; /* You can set this dynamically with JavaScript */
  background: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%);
  transition: width 0.3s ease-in-out;
}

.progress-text {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 30px;
  color: white;
  font-weight: bold;
  z-index: 1;
}

.progress-wrapper {
  position: relative;
  width: 100%;
}

.disabled {
  opacity: .4;
  cursor: not-allowed;
}
body {
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 500px) {
  .cover {
    width: 100%;
    height: auto;
  }
  #face-svg {
    width: 100%;
    height: auto;
  }
}
.cover {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
}
.face {
  position: relative;
  z-index: -1;
}
* {
  margin: 0;
  padding: 0;
}
.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  box-sizing: border-box;
}
.container {
  display: inline-flex;
  /* align-items items in column */
  flex-direction: column;
  /* center items horizontally */
  align-items: center;
  /* center items vertically */
  justify-content: center;
  background-color: #ffffff;
  padding: 0 0 24px;
}
svg {
  background-color: #ffffff;
}
button {
  margin-top: 10px;
  width: 200px;
  padding: 5px;
  background: transparent;
  border-width: 2px;
  font-size: 15px;
  border-color: black;
  color: black;
  font-weight: bold;
  user-select: none;
  border-radius: 10px;
  box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.75);
}

button:not(.disabled):hover {
  background: black;
  color: white;
  transition: 0.3s;
}
button:not(.disabled):active {
  background: rgb(65, 65, 65);
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.75);
}
</style>
